import { FaUserSecret, FaWalking } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import { IoIosRocket } from 'react-icons/io';
import { Link } from '@reach/router';
import { RiRefreshLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import Hero from '../components/landing/Hero';
import Screenshots from '../components/landing/Screenshots';
import Wrapper from '../components/shared/Wrapper';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet>
        <title>{t('shared.appName')}</title>
        <link rel="canonical" href="https://afi24cv.com/" />
      </Helmet>

      <div className="container px-8 xl:px-0 text-center md:text-left mt-24">
        <Hero />

      </div>
    </Wrapper>
  );
};

const Feature = ({ icon: Icon, title, children }) => (
  <div className="mt-16">
    <div className="flex items-center">
      <Icon size="18px" className="text-primary-900 mr-4" />
      <div className="text-3xl">{title}</div>
    </div>
    <p className="mt-6 text-lg leading-loose">{children}</p>
  </div>
);

export default memo(Home);
